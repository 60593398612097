$(document).ready(function() {

    var str = window.location.href ;
    str = str.split('/');
    if(str[3] == '') {
         $('.header').removeClass('fixed-header');
         $('.cc-grower').css('top','0')
        $(window).scroll(function(){
            if ($(window).scrollTop() >= 100) {
                $('.header').addClass('fixed-header');
                $('.cc-grower').css('top','74px')
            }
            else {
                $('.header').removeClass('fixed-header');
                $('.cc-grower').css('top','0')
            }
        });
    }
});
var myPlayer;
$(function () {
    myPlayer = jQuery("#tv").YTPlayer({
        videoURL:'jiBWxnYMKBU',
        optimizeDisplay: true,
        realfullscreen: true,
        remember_last_time: false,
        showYTLogo: false,
        ratio: "auto",
        fadeOnStartTime: 1500,
        containment:'body', 
        showControls:false, 
        autoPlay:true, 
        loop:true, 
        mute:true, 
        opacity:1, 
        addRaster:true, 
        quality:'default', 
        // realfullscreen: true,
        useOnMobile: true,
        showAnnotations: false,
        onReady: function(){
            $('#ytThumb').fadeOut(1000);
        },
    });
    if($(window).width() < 575) {
        if( isMobile.any() ) { 
            myPlayer = jQuery("#tv").YTPlayer({
                videoURL:'o6r_80gElEE',
            });
        };
    }
});
var isMobile = {
    Android: function() {
        return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function() {
        return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function() {
        return navigator.userAgent.match(/iPhone|iPod/i);
    },
    Opera: function() {
        return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function() {
        return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
    },
    any: function() {
        return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
    }
};

$(document).ready(function(){
  $('.open-tooltip').hover(function(){
    $(this).siblings('.plan-tooltip').toggle();
  });
  $('a[href^="#"]').on('click', function(e) {
    e.preventDefault()
    $('html, body').animate(
      {
        scrollTop: $($(this).attr('href')).offset().top + 75
      },1000
    )
  });
});